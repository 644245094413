import axios from "axios";

export const url = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:5000";

const api = axios.create({
  baseURL: `${url}/`,
});

export default api;
