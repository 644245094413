import React from "react";
import { useState } from "react";
import Webcam from "react-webcam";
import api from "../../services/api";
import { notifyUser } from "../../utils";
import { Link } from "react-router-dom";

export default function Upload() {
  const webcamRef = React.useRef(null);
  const [message, setMessage] = useState("");
  const consented = localStorage.getItem("biofeaturewellness_consent");

  const capture = React.useCallback(
    async () => {
      const file = await fetch(webcamRef.current.getScreenshot()).then(res => res.blob())

      var formData = new FormData();
      formData.append("file", file);
      formData.append("key", localStorage.getItem("biofeaturewellness_key"));

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      try {
        await api.post("/predictions/check", formData, config);
        try {
          await api.post("/predictions/async/bykey", formData, config)
          setMessage("✅ Obrigado! Sua imagem será analisada por nosso time e entraremos em contato caso necessário!");
        } catch (error) {
          notifyUser("Ocorreu um erro e sua imagem não foi enviada. Entre em contato conosco para solicitar um novo link.");
        }
      } catch (error) {
        notifyUser("Erro na validação da imagem, favor tentar novamente.");
      }

    },
    [webcamRef]
  );

  return (
    <div className="flex-column width-100p">
      {
        message ?
          <div className="flex-column flex-crossaxis-center min-height-100vh max-width-xxxxlarge margin-auto padding-small">
            {message}
          </div> :
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
            {
              consented ?
                <button className="button-tertiary margin-top-small" onClick={capture}>Capturar foto</button> :
                <p className="max-width-xxxxlarge margin-auto padding-small">
                  Você precisa <Link to="/consent" className="bold">aceitar o termo de consentimento</Link> para continuar.
                </p>
            }
          </>
      }

    </div>
  );
}
