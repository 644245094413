import React, { useState } from "react";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useNavigate } from "react-router-dom";

export default function Consent() {
  const [checked, setChecked] = useState(false);
  let navigate = useNavigate();

  function handleContinue() {
    localStorage.setItem("biofeaturewellness_consent", true)
    navigate("/upload");
  }

  return (
    <div className="flex-column flex-crossaxis-center min-height-100vh max-width-xxxxlarge margin-auto padding-small">
      <span className="color-tertiary bold size-xxxlarge margin-bottom-large text-center">Instruções</span>
      <p>Identificar fatores de risco para doenças é fundamental para prevenção e tratamento precoce.</p>
      <p>Por isso, precisamos tirar uma foto do seu rosto. Ela será avaliada por nossa inteligência artificial e por especialistas em saúde preventiva.</p>
      <p className="margin-bottom-large">Todo o procedimento levará apenas alguns segundos.</p>
      <button className="flex-row flex-axis-center margin-bottom-large" onClick={() => setChecked(!checked)}>
        {
          checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
        }
        <span className="margin-left-small text-left">Concordo com a <a href="https://lablift.com.br/privacy.pdf" target="_blank" rel="noreferrer" className="bold">Política de Privacidade e Processamento de Dados</a></span>
      </button>
      {
        checked ?
          <button className="button-tertiary" onClick={handleContinue}>Continuar</button> :
          <button className="button-tertiary-disabled" disabled>Continuar</button>
      }

    </div>
  );
}
