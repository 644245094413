import { store } from "react-notifications-component";
import { makeStyles } from "@material-ui/core";

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function notifyUser(message, title = "Error", type = "danger") {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export const notifyError = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors
  ) {
    let jsonErrors = error.response.data.errors.json;
    let queryErrors = error.response.data.errors.query;

    if (jsonErrors) {
      for (let [title, values] of Object.entries(jsonErrors)) {
        for (let [title2, message] of Object.entries(values)) {
          if (typeof message === "string") {
            notifyUser(message, title);
          } else {
            for (let [, field] of Object.entries(message)) {
              if (typeof field === "string") {
                notifyUser(field, title2);
              } else {
                for (let [title4, imessage] of Object.entries(field)) {
                  notifyUser(imessage, title4);
                }
              }
            }
          }
        }
      }
    }
    if (queryErrors) {
      for (let [title, values] of Object.entries(queryErrors)) {
        for (let [, message] of Object.entries(values)) {
          if (typeof message === "string") {
            notifyUser(message, title);
          }
        }
      }
    }
  } else {
    notifyUser("Network error. Please try again later");
  }
};

export const formStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
