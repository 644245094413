import React from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import "./global.css";

import Routes from "./routes";

function App() {
  return (
    <div>
      <ReactNotification />
      <Routes />
    </div>
  );
}

export default App;
