import React, { useEffect, useState } from "react";
// import TextField from "@material-ui/core/TextField";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import { notifyUser } from "../../utils";
import api from "../../services/api";

export default function Index() {
  const [searchParams] = useSearchParams();
  // const [key, setKey] = useState(searchParams.get("key") ? searchParams.get("key") : "");
  const [loading, setLoading] = useState(true);
  const key = searchParams.get("key") ? searchParams.get("key") : "";
  let navigate = useNavigate();

  useEffect(() => {
    async function checkKey() {

      try {
        await api.get(`keys/${key}`);
        localStorage.setItem("biofeaturewellness_key", key)
        setLoading(false);
        navigate("/consent");
      } catch (error) {
        // notifyError(error);
        notifyUser("Não conseguimos processar sua solicitação. Favor tentar mais tarde ou solicitar um novo link.", "Erro");
        setLoading(false);
      }
    }

    // if (key !== "") {
    checkKey();
    // }
  }, [navigate, key])

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   navigate("/consent");
  // }

  return (
    !loading &&
    <div className="flex-column flex-axis-center flex-crossaxis-center min-height-100vh max-width-xxxxlarge margin-auto padding-small">
      <span className="color-tertiary bold size-xxxlarge margin-bottom-large">LabLift</span>
      <p className="margin-bottom-large">Para cuidar bem de você, precisamos te conhecer melhor.</p>
      {/* <form onSubmit={handleSubmit} className="width-100p">
        <TextField
          required
          className="width-100p"
          label="Código"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
        <button className="button-tertiary width-100p margin-top-xlarge" type="submit">Iniciar</button>
      </form> */}
    </div>
  );
}
