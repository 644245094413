import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";

import PrivateRoute from "./components/PrivateRoute";
import Consent from "./pages/Consent";
import Upload from "./pages/Upload";

export default function RoutesModule() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route element={<PrivateRoute />}>
          <Route path="/consent" element={<Consent />} />
          <Route path="/upload" element={<Upload />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
